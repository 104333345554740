import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Form from 'Form'
import Seo from 'SEO'

const FormTemplate = ({ data }) => {
  const {
    html,
    frontmatter,
    fields,
    excerpt
  } = data.markdownRemark

  return (
    <>
      <Seo
        title={frontmatter.title}
        description={excerpt}
        slug={fields.slug}
        image={frontmatter.image}
      />
      <Form
        body={html}
        {...frontmatter}
      />
    </>
  )
}

FormTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default FormTemplate

export const query = graphql`
  query Form($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        image
        mailchimpJoinedVia
        success
        ctaLabel
      }
    }
  }
`
