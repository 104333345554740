import { graphql, useStaticQuery } from 'gatsby'

const useForms = () => {
  const { frontmatter: data } = useStaticQuery(
    graphql`
      query {
        markdownRemark (
          fields: {
            slug: {
              regex: "/snippets\/forms/"
            }
          }
        ) {
          frontmatter {
            emailFieldLabel
            gdpr
          }
        }
      }
    `
  ).markdownRemark

  return data
}

export default useForms
