/* global FormData */
import React, { useState } from 'react'
import classNames from 'classnames'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { HTMLContent } from 'Content'
import Figure from 'Figure'

import useForms from 'hooks/useFormsQuery'

import toHTML from 'utils/toHTML'

import ArrowSVG from 'assets/img/svg/arrow-right.svg'
import LogoSVG from 'assets/img/svg/logo.svg'

const Form = (props) => {
  const { title, body, image, success, mailchimpJoinedVia, ctaLabel } = props

  const { emailFieldLabel, gdpr } = useForms()

  const [status, setStatus] = useState({
    result: '',
    msg: ''
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    const formData = new FormData(event.target)
    addToMailchimp(formData.get('EMAIL'), {
      FNAME: formData.get('FNAME'),
      JOINEDVIA: mailchimpJoinedVia
    })
      .then((data) => {
        setStatus(data)
      })
  }

  return (
    <div className='c-form'>
      <header className='c-form-header'>
        <div className='c-header__logo c-header__logo--white c-form-header__logo'>
          <LogoSVG />
        </div>
      </header>
      <main
        className={classNames('c-form__main', {
          'c-form__main--with-image': image,
          'c-form__main--without-image': !image,
        })}
      >
        <div className='c-form__inner'>
          <div className='c-form__content'>
            <h1>{title}</h1>
            <HTMLContent
              content={body}
              className='c-form__body'
            />
            <div className='c-form__wrapper'>
              <form
                action='https://tripledoublestudio.us18.list-manage.com/subscribe/post?u=0acc911482eb864c693688998&id=ee3531ce69'
                method='POST'
                className={classNames('c-form__form', {
                  'c-form__form--submitted': (status.result === 'success')
                })}
                onSubmit={handleSubmit}
              >
                {[{
                  label: 'First name',
                  name: 'FNAME',
                  type: 'text'
                }, {
                  label: emailFieldLabel,
                  name: 'EMAIL',
                  type: 'email'
                }].map((item, itemIndex) => {
                  return (
                    <div
                      key={itemIndex}
                      className='c-form__row'
                    >
                      <label
                        htmlFor={item.name}
                        className='c-form__label'
                      >
                        {item.label}
                      </label>
                      <input
                        type={item.type}
                        name={item.name}
                        id={item.name}
                        className='c-form__input'
                        required
                      />
                    </div>
                  )
                })}
                {status.result === 'error' && (
                  <p
                    className='u-pad-t o-rte'
                    dangerouslySetInnerHTML={{ __html: status.msg }}
                  />
                )}
                <button
                  type='submit'
                  className='c-form__cta'
                >
                  {ctaLabel}
                  <ArrowSVG />
                </button>
                {gdpr && (
                  <HTMLContent
                    content={toHTML(gdpr)}
                    className='c-form__input-help'
                  />
                )}
              </form>
              <div
                className={classNames('c-form__success', {
                  'c-form__success--submitted': (status.result === 'success')
                })}
              >
                <p>{success}</p>
              </div>
            </div>
          </div>
          {image && (
            <div className='c-form__img'>
              <Figure
                image={image}
                width={1000}
                className='c-form__figure u-img-full'
                lazyload
              />
            </div>
          )}
        </div>
      </main>
    </div>
  )
}

export default Form
